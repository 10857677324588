.date-range-picker-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.date-picker-label {
  margin-bottom: 4px;
  font-size: 16px;
  color: #333;
}

.react-datepicker {
  font-size: 16px;
  z-index: 9;
}

.react-datepicker__input-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.react-datepicker__header {
  background: linear-gradient(to bottom, #9ac1fa, #c5f5de);
  color: white;
}

.react-datepicker__day--selected {
  background-color: #9dc5f8;
  color: #344559;
}

.react-datepicker__day:hover {
  background-color: #344559;
  color: white;
}

.react-datepicker-popper {
  z-index: 9;
}

.react-datepicker__navigation-icon::before {
  border-color: #fff;
}
