/* .tickerinfo{
  background-color: #F2DFCE;

}
.gen{
  padding:3px;
  padding-right:5px;
} */

.tickerdetails {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 1rem 2rem;
}

.tickerdetails div {
  background: linear-gradient(to left, #9ac1fa, #c5f5de);
  min-width: 8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
/* }
.tickersmallhead {
  color:rgb(117, 117, 117)
  

}
.tickerdata{

} */

/* CSS */
.tickerinfo {
  background-color: #17273b;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  margin-bottom: 2rem;
}

/* .tickername {
  margin-bottom: 20px;
} */

.tickername h2 {
  color: #bfede2;
  font-size: 1.75rem;
  letter-spacing: 0.15rem;
  line-height: 1.2;
  font-family: monospace;
  font-weight: 700;
}

.gen {
  /* padding: 3px;
  padding-right: 15px; */
  padding: 4px;
  border-radius: 8px;
  /* text-align: center; */
  /* margin-right: 10px; */
  transition: all 0.4s ease;
}

.gen:hover {
  background: linear-gradient(to right, #9ac1fa, #c5f5de);
  transform: scale(1.1);
}

.tickersmallhead {
  color: #5a5a5a;
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
  margin-bottom: 0px;
}

.tickerdata {
  font-size: 15px;
  font-weight: 600;
  color: #333;
}

.fetch_button {
  color: #bfede2;
  background-color: #344559;
  border: none;
  padding: 1rem 2rem;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.fetch_button:hover {
  background-color: hwb(213 9% 77%);
}

.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.etfdata_container {
  margin: 2rem 0;
  background: linear-gradient(to right, #9ac1fa, #c5f5de);
  padding: 1rem;
  padding-bottom: 2.5rem;
}

.etfdataTable {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

/* Styles for MUI menu */
.css-6hp17o-MuiList-root-MuiMenu-list {
  background: linear-gradient(to bottom, #9ac1fa, #c5f5de, #98befc) !important;
}

.MuiList-root {
  background: linear-gradient(to bottom, #9ac1fa, #c5f5de, #98befc) !important;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root:hover {
  background: #6e9ef1 !important;
}

/* Styles for MUI tables */
.MuiTableBody-root {
  background: linear-gradient(to left, #9ac1fa, #c5f5de) !important;
}

.css-dsuxgy-MuiTableCell-root {
  border-bottom: 1px solid #17273b !important;
}

/* Styles for small screens */
@media only screen and (max-width: 600px) {
  .tickerdetails {
    padding: 1rem 0rem;
    justify-content: center;
    gap: 0.5rem;
  }

  .tickerdetails div {
    min-width: 6.5rem;
  }

  .etfdataTable {
    flex-direction: column;
  }

  .waiting_text {
    width: 90%;
    text-align: center;
    white-space: initial;
  }
}
